*{
    color:white;
}

body{
    text-align: center;
    font-size: large;
    font-family: 'Courier New', monospace;
    background-color: #222222;
    color:white;
}

.card-body * {
    color: black;
}

.text-left{
    text-align: left !important;
}

.row{
    margin: 10px;
}

[id*="Main"]{
    margin-top: 15px;
}

@media only screen and (max-width: 750px) {
    /* For mobile phones: */
    [class*="col-"], .ms-3 {
        width: 100%;
    }
    .card{
        margin-top: 10px;
    }
}